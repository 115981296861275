import * as React from 'react'
import OnboardingLayout from '../../layout/OnboardingLayout'
import SEO from '../../components/seo'
import OnboardingApp from '../../components/onboarding/onboardingApp'

export const FreemiumTemplate = ({ pageContext, data, location }) => {
  let layout = (
    <OnboardingLayout>
      <SEO description="" title="Bli medlem" />
      <OnboardingApp base="testa-dik" />
    </OnboardingLayout>
  )

  return layout
}

export default FreemiumTemplate
